const CheckIcon = ({ size = 20 }: { size?: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}`}
      height={`${size}`}
      viewBox="0 0 32 32"
      fill="none"
    >
      <circle
        cx="16"
        cy="16"
        r="14.9062"
        fill="#27AE60"
        stroke="white"
        strokeWidth="2.1875"
      />
      <path
        d="M7.99988 17L13.3332 22L23.9999 10"
        stroke="white"
        strokeWidth="3.28125"
      />
    </svg>
  );
};

export default CheckIcon;
