import NextImage, { ImageProps } from "next/image";

const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#eeeeee" offset="20%" />
      <stop stop-color="#dddddd" offset="50%" />
      <stop stop-color="#eeeeee" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#eeeeee" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite" />
</svg>`;

const toBase64 = (str: string) =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str);

const NextImageWithLoader = (props: ImageProps) => {
  return (
    <NextImage
      blurDataURL={`data:image/svg+xml;base64,${toBase64(
        shimmer(props.width as number, props.height as number)
      )}`}
      placeholder="blur"
      {...props}
    />
  );
};

export default NextImageWithLoader;
