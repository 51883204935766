import { useState } from "react";
import Image from "next/image";
import NextImageWithLoader from "./NextImageWithLoader";
import Link from "next/link";
import {
  CARD_IMAGE_SIZE_WIDTH,
  CARD_IMAGE_SIZE_HEIGHT,
} from "@/libs/constants";
import { useAuthValue } from "@/contexts/contextAuth";
import { setScrollHidden, checkHomePage } from "@/libs/utils";
import { makeApiImage } from "@/libs/clientConfig";
import Profile from "./Profile";

type Props = {
  data: any;
  index: number;
  isNative?: boolean;
};

const VehicleCard = ({ data, index, isNative }: Props) => {
  const [isHovering, setIsHovered] = useState<Boolean>(false);
  const [hoverNum, setHoverNum] = useState<number>(-1);
  const { setIsSignup, setIsFavMark } = useAuthValue();

  const onMouseEnter = (index: number) => {
    setIsHovered(true);
    setHoverNum(index);
  };
  const onMouseLeave = (index: number) => {
    setIsHovered(false);
    setHoverNum(index);
  };

  const markVehicleFavorite = () => {
    const isFavMark = `${data.slug}?action=fav&favId=${data._id}`;
    setIsFavMark(isFavMark);
    setIsSignup(true, { isFavMark });
    setScrollHidden();
  };

  const scrubbedSlug = data.slug.startsWith("/listing/")
    ? data.slug.slice(9)
    : data.slug.startsWith("listing/")
    ? data.slug.slice(8)
    : data.slug;

  return (
    <div
      className="block w-full h-full bg-white rounded-lg shadow cursor-pointer"
      key={data._id}
    >
      <div className={`relative w-full overflow-hidden rounded-t-lg`}>
        <Link
          href={`/${isNative ? "native/" : ""}listing/${scrubbedSlug}`}
          onClick={() => {
            sessionStorage.setItem("previousUrl", window.location.href);
          }}
        >
          <NextImageWithLoader
            width={CARD_IMAGE_SIZE_WIDTH}
            height={CARD_IMAGE_SIZE_HEIGHT}
            className={`w-full ${
              checkHomePage() ? "md:h-[160px]" : "md:h-[242px]"
            } object-cover`}
            style={{
              width: "100%",
              height: `${CARD_IMAGE_SIZE_HEIGHT}px`,
            }}
            src={makeApiImage(data.uploadImages[0]?.images)}
            alt={`${data.CarMake}_${data.CarModel} `}
          />
        </Link>
        <div
          onMouseEnter={() => onMouseEnter(index)}
          onMouseLeave={() => onMouseLeave(index)}
        >
          {data.hasPendingDeal && (
            <div className="absolute top-1 left-1 z-10 l rounded-md bg-[#f2994a] text-center text-white w-16 h-6 uppercase py-2 px-2 font-semibold text-xs leading-4 flex items-center justify-center">
              Pending
            </div>
          )}

          <Image
            width={20}
            height={20}
            className="absolute top-1 right-1"
            onClick={() => {
              markVehicleFavorite();
            }}
            src="/assets/fav-heart.svg"
            alt="heart"
          />
          {isHovering && hoverNum == index && (
            <Image
              width={20}
              height={20}
              className="absolute top-1 right-1"
              onClick={() => {
                markVehicleFavorite();
              }}
              src="/assets/fav-active.svg"
              alt="heart"
            />
          )}
        </div>
      </div>
      <Link href={`/${isNative ? "native/" : ""}listing/${scrubbedSlug}`}>
        <div className="px-4 py-4">
          <div className="text-lg font-bold text-[#212529]">
            {data.RegistrationYear} {data.CarMake} {data.CarModel}
          </div>
          <div
            className={`w-full sm:h-[48px] flex ${
              checkHomePage() ? "space-x-1" : "space-x-2"
            } items-start text-base text-[#313538] font-medium`}
          >
            <div>{data.Trim?.slice(0, 12)}</div>
            <div
              className={`flex items-center ${
                checkHomePage() ? "space-x-1" : "space-x-2"
              }`}
            >
              <div className="w-[3px] h-[3px] bg-[#313538] rounded-full"></div>
              <div>
                {data.Mileage ? data.Mileage.toLocaleString() : "-"}&nbsp;miles
              </div>
            </div>
          </div>
          {data.listingLocation ? (
            <p className="font-sm text-[#808080] font-normal">
              {data.listingLocation.city},&nbsp;
              {data.listingLocation.stateShortname}
            </p>
          ) : (
            <p className="font-sm text-[#808080] font-normal">N/A</p>
          )}
          <div className="flex justify-between items-center">
            <div className="text-xl text-[#212529] font-bold">
              ${(data.Price || "").toLocaleString()}
            </div>

            {data?.originalSourceInfo ? (
              <div className="bg-[#FFECD6] rounded px-3 py-1 font-semibold text-[#C46800]">
                <span>Off-site listing</span>
              </div>
            ) : (
              <Profile data={data} imgWidthAndHeight={35} />
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default VehicleCard;
